import React from "react";
import { concatClassNames as cn } from "@system42/core";
import {
  ButtonLarge,
  ButtonPrimaryLarge,
  urlKnowledgeBase,
  urlKnowledgeBaseTester,
  urlBookADemo,
} from "../../system42";
import * as styles from "./styles.module.css";
import {} from "../../helpers";
import {
  emailSales,
  emailCustomerSupport,
  emailTesterSupport,
  addUtmParams,
} from "@simplease/system42-theme-userbrain";

export function ContactBoxes(props) {
  const { className, ...containerProps } = props;

  return (
    <div className={cn(className, styles.contactBoxes)} {...containerProps}>
      <div className={styles.contactBox}>
        <h2>Sales</h2>
        <p>Get in touch with our sales team to see how we can work together.</p>
        <ButtonPrimaryLarge
          className={styles.button}
          customTag={"a"}
          href={`mailto:${emailSales}`}
        >
          Contact sales
        </ButtonPrimaryLarge>
        <ButtonLarge
          className={styles.button}
          customTag={"a"}
          href={addUtmParams(urlBookADemo, "website", "contact")}
        >
          Book a demo
        </ButtonLarge>
      </div>
      <div className={styles.contactBox}>
        <h2>Customer support</h2>
        <p>
          Stuck on a question you couldn’t find the answer to in our knowledge
          base?
        </p>
        <ButtonPrimaryLarge
          className={styles.button}
          customTag={"a"}
          href={urlKnowledgeBase}
        >
          Visit knowledge base
        </ButtonPrimaryLarge>
        <ButtonLarge
          className={styles.button}
          customTag={"a"}
          href={`mailto:${emailCustomerSupport}`}
        >
          Submit a question
        </ButtonLarge>
      </div>
      <div className={styles.contactBox}>
        <h2>Tester support</h2>
        <p>
          Stuck on a question you couldn’t find the answer to in our knowledge
          base?
        </p>
        <ButtonPrimaryLarge
          className={styles.button}
          customTag={"a"}
          href={urlKnowledgeBaseTester}
        >
          Visit knowledge base
        </ButtonPrimaryLarge>
        <ButtonLarge
          className={styles.button}
          customTag={"a"}
          href={`mailto:${emailTesterSupport}`}
        >
          Submit a question
        </ButtonLarge>
      </div>
    </div>
  );
}
