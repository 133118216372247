import React from "react";
import { concatClassNames as cn } from "@system42/core";
import { Headline1Sans, Overline } from "../../system42";
import * as styles from "./styles.module.css";

export function HeaderTitleBlank(props) {
  const { className, children, overline, titleWidth, ...containerProps } =
    props;

  return (
    <header className={cn(className, styles.headerTitle)} {...containerProps}>
      {overline && <Overline className={styles.overline}>{overline}</Overline>}
      <Headline1Sans className={styles.title} style={{ maxWidth: titleWidth }}>
        {children}
      </Headline1Sans>
    </header>
  );
}
